<template>
  <div>
    <div class="flix-row">
      <div class="flix-col-md-4" >
        <div class="flix-form-group flix-text-center flix-img-container">
          <img :src="$flix_url + '/assets/img/reminder/flix_fant.gif'">
        </div>
        <div class="flix-title-container flix-form-group">
          {{ $t('message.reminderPreset1') }}
        </div>
        <div class="flix-reminder-length flix-form-group">
           <flixIcon :id='"pending-notifications"' /> 1 {{ $tc('message.reminder', 1) }}
        </div>
        <div class="flix-form-group">
          <a href="#" @click.prevent="getSave([{'format': 'email', 'trigger_int': 1, 'trigger_format': 'days'}])" class="flix-btn flix-btn-primary flix-btn-block">{{ $t('message.save', {name: ''}) }}</a>
        </div>
        <div class="flix-reminder-list flix-form-group">
           <ul>
             <li><flixIcon :id="'envelope'" /> <span>{{ $tc('reminder.email', 1) }} {{ $tc('message.reminder', 1) }}</span>3 {{ $t('message.days') }} vor Termin</li>
          </ul>
        </div>
      </div>
      <div class="flix-col-md-4">
        <div class="flix-form-group flix-text-center flix-img-container">
          <img :src="$flix_url + '/assets/img/reminder/flix_hoernchen.gif'">
        </div>
        <div class="flix-title-container flix-form-group">
          {{ $t('message.reminderPreset2') }}
        </div>
        <div class="flix-reminder-length flix-form-group">
           <flixIcon :id='"pending-notifications"' /> 3 {{ $tc('message.reminder', 2) }}
        </div>
        <div class="flix-form-group">
          <a v-if="sms" href="#" @click.prevent="getSave([{'format': 'email', 'trigger_int': 7, 'trigger_format': 'days'}, {'format': 'email', 'trigger_int': 2, 'trigger_format': 'days'}, {'format': 'tel', 'trigger_int': 1, 'trigger_format': 'days'}])" class="flix-btn flix-btn-primary flix-btn-block">{{ $t('message.save', {name: ''}) }}</a>
          <router-link v-else class="flix-html-a flix-text-danger" :to="{name: 'dashboardCredits'}"><flixIcon :id="'warning-sign'" /> {{ $t('message.creditsForSms') }}</router-link>
        </div>
        <div class="flix-reminder-list flix-form-group">
           <ul>
             <li><flixIcon :id="'envelope'" /> <span>{{ $tc('reminder.email', 1) }} {{ $tc('message.reminder', 1) }}</span>7 {{ $t('message.days') }} vor Termin</li>
             <li><flixIcon :id="'envelope'" /> <span>{{ $tc('reminder.email', 1) }} {{ $tc('message.reminder', 1) }}</span>2 {{ $t('message.days') }} vor Termin</li>
             <li><flixIcon :id="'iphone'" /> <span>{{ $tc('reminder.tel', 1) }} {{ $tc('message.reminder', 1) }}</span>1 {{ $t('message.day') }} vor Termin</li>
           </ul>
        </div>
      </div>
      <div class="flix-col-md-4">
        <div class="flix-form-group flix-text-center flix-img-container">
          <img :src="$flix_url + '/assets/img/reminder/flix_mingo.gif'">
        </div>
        <div class="flix-title-container flix-form-group">
          {{ $t('message.reminderPreset3') }}
        </div>
        <div class="flix-reminder-length flix-form-group">
           <flixIcon :id='"pending-notifications"' /> 5 {{ $tc('message.reminder', 2) }}
        </div>
        <div class="flix-form-group">
          <a v-if="sms" href="#" @click.prevent="getSave([{'format': 'email', 'trigger_int': 14, 'trigger_format': 'days'}, {'format': 'email', 'trigger_int': 7, 'trigger_format': 'days'}, {'format': 'tel', 'trigger_int': 4, 'trigger_format': 'days'}, {'format': 'email', 'trigger_int': 2, 'trigger_format': 'days'}, {'format': 'tel', 'trigger_int': 1, 'trigger_format': 'days'}])" class="flix-btn flix-btn-primary flix-btn-block">{{ $t('message.save', {name: ''}) }}</a>
          <router-link v-else class="flix-html-a flix-text-danger" :to="{name: 'dashboardCredits'}"><flixIcon :id="'warning-sign'" /> {{ $t('message.creditsForSms') }}</router-link>
        </div>
        <div class="flix-reminder-list flix-form-group">
          <ul>
            <li><flixIcon :id="'envelope'" /> <span>{{ $tc('reminder.email', 1) }} {{ $tc('message.reminder', 1) }}</span>14 {{ $t('message.days') }} vor Termin</li>
            <li><flixIcon :id="'envelope'" /> <span>{{ $tc('reminder.email', 1) }} {{ $tc('message.reminder', 1) }}</span>7 {{ $t('message.days') }} vor Termin</li>
            <li><flixIcon :id="'iphone'" /> <span>{{ $tc('reminder.tel', 1) }} {{ $tc('message.reminder', 1) }}</span>4 {{ $t('message.days') }} vor Termin</li>
            <li><flixIcon :id="'envelope'" /> <span>{{ $tc('reminder.email', 1) }} {{ $tc('message.reminder', 1) }}</span>2 {{ $t('message.days') }} vor Termin</li>
            <li><flixIcon :id="'iphone'" /> <span>{{ $tc('reminder.tel', 1) }} {{ $tc('message.reminder', 1) }}</span>1 {{ $t('message.day') }} vor Termin</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    callback: Function
  },
  data () {
    return {
      variables: this.$getUserVariables(),
      sms: false
    }
  },
  methods: {
    getSave (data) {
      var def = this.$store.getters.defaultReminder
      var reminders = []
      data.forEach(function (val, k) {
        var insert = Object.assign(JSON.parse(JSON.stringify(def)), val)
        insert.ID = (insert.ID * 1) + k
        insert.to = this.$route.params.id
        insert.title = this.$tc('message.reminder', 1)
        insert.msg = this.$t('reminder.standard')
        reminders.push(insert)
      }.bind(this))
      this.$store.commit('setReminders', reminders)
      this.$store.dispatch('saveReminder', { ID: this.$route.params.id, data: reminders })
      this.callback(reminders)
    },
    getCheckBookingForm () {
      this.$store.getters.assistents.forEach(function (assistent) {
        if (assistent.ID === this.$route.params.id) {
          this.$flix_post({
            url: 'assistent/get_data',
            data: { user: this.variables.user.md5_id, ID: assistent.ID },
            callback: function (ret) {
              if (!ret.data[0]) {
                return false
              }
              ret.data[1].form.forEach(function (elem) {
                if (elem.subtype === 'tel') {
                  this.sms = true
                }
              }.bind(this))
            }.bind(this)
          })
        }
      }.bind(this))
    },
    getCheckSMS () {
      if (!this.variables.user.first_name) {
        return false
      }
      if (this.variables.user.sms * 1 <= 0) {
        return false
      }
      this.getCheckBookingForm()
    }
  },
  mounted () {
    this.getCheckSMS()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  .flix-img-container
    background-color: #eee
    padding-top: 10px
    border-bottom: 5px solid grey
    img
      height: 200px
  .flix-title-container
    font-weight: bold
    text-align: center
    font-size: 10pt
    font-style: italic
  .flix-reminder-length
    background-color: grey
    text-align: center
    padding: 5px
    color: white
  .flix-reminder-list
    ul
      padding: 0
      padding-left: 15px
      margin: 0
      li
        text-align: left
        line-height: 1.6em
        list-style-type: none
        margin: 0
        margin-bottom: 9px
        i
          float: left
          margin-right: 5px
          font-size: 18pt
        span
          display: block
          font-weight: bold
  @media (max-width: 500px)
    .flix-col-md-4
      margin-bottom: 80px
</style>
